import { computed } from '@angular/core';
import { $useAccountsCache } from './$use-accounts-cache';
import { eAccountStatus } from '@pwiz/entity/ts';

export function $useAccounts(
  statusList: eAccountStatus[] = [eAccountStatus.customer],
) {
  const $cache = $useAccountsCache(statusList);
  return computed(() => $cache().data || []);
}
