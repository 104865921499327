import { computed, inject } from '@angular/core';
import { AccountsService } from '../accounts.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { eAccountStatus } from '@pwiz/entity/ts';

export function $useAccountsCache(statusList?: eAccountStatus[]) {
  const accountService = inject(AccountsService);
  const accounts$ = toSignal(accountService.getAccounts$(), {
    requireSync: true,
  });
  if (!statusList) {
    return accounts$;
  }
  return computed(() => {
    const { status, data } = accounts$();
    if (status === 'success' && data) {
      return {
        status,
        data: data.filter((account) => statusList.includes(account.status)),
      };
    }
    return { status, data };
  });
}
