import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { map, Subject, tap } from 'rxjs';
import { useEnv } from '@pwiz/infra/environment';
import { PwCache, pwCacheUpdateEntityArr } from '@pwiz/infra/cache/ts';
import {
  IAccountDetailsRes,
  PW_ACCOUNT_API_BASE,
  toUiAccountDetails,
  UiAccount,
} from '@pwiz/account/ts';

@Injectable({ providedIn: 'root' })
export class AccountsService implements OnDestroy {
  #backendUrl = useEnv().backendUrl;
  #http = inject(HttpClient);
  #cache = new PwCache<UiAccount[]>(
    null,
    pwCacheUpdateEntityArr,
    this.#getAll(),
  );
  #stopPolling$: Subject<boolean> = new Subject();

  #getAll() {
    return this.#http.get<UiAccount[]>(
      `${this.#backendUrl}/api/${PW_ACCOUNT_API_BASE}`,
    );
  }

  getAccounts$() {
    return this.#cache.getV2$();
  }

  getAccountDetails$(id: string) {
    return this.#http
      .get<IAccountDetailsRes>(
        `${this.#backendUrl}/api/${PW_ACCOUNT_API_BASE}/${id}`,
      )
      .pipe(
        map(toUiAccountDetails),
        tap((account) => this.#cache.update([account], 'success')),
      );
  }

  ngOnDestroy() {
    this.#stopPolling$.next(true);
    this.#stopPolling$.complete();
  }
}
